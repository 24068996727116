import React, { useState, useRef, useEffect } from 'react'

export default function InfoBox(props) {
    const [active, setActive] = useState(false);
    const panelRef = useRef(null);

    useEffect(() => {
        if (props.width >= 800) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }, [props.width])

    return (
        <div className="info-box">
            <div className="content_box">
                <button
                    className={"accordion" + (active ? " active" : " closed")}
                    onClick={() => {
                        if (props.width < 800) {
                            setActive(currentState => !currentState);
                        }
                    }
                    }
                >
                    {props.title}
                    <span className="icon">{active ? String.fromCharCode(8722) : String.fromCharCode(43)}</span>
                </button>
                <div
                    className="panel"
                    ref={panelRef}
                    style={
                        active ?
                            {
                                maxHeight: panelRef.current.scrollHeight
                            } :
                            {
                                maxHeight: null
                            }
                    }
                >
                    <div className="content">
                        {props.content}
                    </div>
                </div>
            </div>
        </div>
    )
}