import React from "react";
import Project from "../Components/Project"

export default function Projects(props) {
    const projects = [
        {
            title: "Custom 3D renderer",
            description: "Using my skills in linear algebra, I created a 3D-engine from scratch just by drawing polygons in python. By not relying on any libraries, I got great insight into how computers are able to render graphics.",
            start: "June 2020",
            stop: "August 2020",
            src: "https://github.com/IsakRein/3D-Engine",
            technologies: [
                "Python",
                "Pygame",
                "Linear Algebra"
            ],
            content: {
                src: "videos/3d-renderer.mov",
                type: "video"
            }
        },
        {
            title: "Biden vs Trump",
            description: "A mobile game I made for the 2020 United States presidential election. Created with the Unity game engine, with some backend work in Node.js. Released for both Android and iOS, reaching thousands of players.",
            start: "April 2020",
            stop: "November 2020",
            src: "https://github.com/IsakRein/Biden-vs-Trump",
            technologies: [
                "Unity",
                "C#",
                "Node.js",
                "PostgreSQL",
                "Photoshop"
            ],
            content: {
                src: "videos/biden_vs_trump.mov",
                type: "video"
            }
        },
        {
            title: "Genetic Stock Trader",
            description: "By webscraping the popular swedish bank Avanza, I am able to gather realtime stock data and do intra-day trading. The system is fully automated and by analyzing previous data, the algorithms evolve themselves genetically. The bot is mainly built in python, with the exception of the training which is written in C++ via pybind11.",
            start: "October 2019",
            stop: "present",
            src: "https://github.com/IsakRein/Genetic-StockTrader",
            technologies: [
                "Python",
				"C++",
                "Pybind11",
                "Numpy",
                "Pandas",
                "Algorithms and Datastructures"
            ],
            content: {
                src: "images/bluebook.jpeg",
                type: "image"
            }
        },
        {
            title: "Multiplayer Tetris",
            description: "To further my understanding of how networking works, I recreated the popular game tetris for two players with websockets. All the networking logic was written from scratch to optimize the performance. This resulted in a scalable system, that could handle many clients at once.",
            start: "April 2021",
            stop: "June 2021",
            src: "https://github.com/IsakRein/multiplayer-tetris",
            technologies: [
                "React",
                "Websockets",
                "Node.js",
                "JavaScript",
                "Algorithms and Datastructures"
            ],
            content: {
                src: "videos/tetris.mov",
                type: "video"
            }
        },
        {
            title: "Piece together",
            description: "In frustration over the low quality and ad-filled mobile gaming space, I sought to create a mobile game that people actually wanted to play. The result was: Piece Together, a relaxing puzzle game with 1200 levels (which were automatically generated). Like Biden vs Trump, it was written in C# together with the Unity game engine.",
            start: "June 2018",
            stop: "January 2019",
            src: "https://github.com/IsakRein/PieceTogether",
            technologies: [
                "Unity",
                "C#",
                "Photoshop"
            ],
            content: {
                src: "videos/piece_together.mp4",
                type: "video"
            }
        }
    ]


    const renderProjects = () => {
        const newProjects = [];
        for (var i = 0; i < projects.length; i++) {
            const project = projects[i];
            newProjects.push(
                (
                    <div key={i}>
                        <Project
                            width={props.width}
                            attributes={project}
                            position={(i % 2 === 1) || props.width < 800 ? "right" : "left"}
                        />
                        {i < projects.length - 1 && <hr></hr>}
                    </div>
                )
            )
        }
        return newProjects;
    }

    return (
        <div
            id="projects"
            className="projects">


            <div className="text-container">
                <h3>Some cool things I've done.</h3>
            </div>

            <div className="projects-container">
                {
                    renderProjects()
                }
            </div>

        </div>
    )
}


