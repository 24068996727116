import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import { random, randomSigned, mod } from "../Utilities"
import BezierEasing from "bezier-easing"

class Node {
    constructor(x, y, radius, speedX, speedY) {
        this.animationTime = 0.0;
        this.animationDuration = 0.3;
        this.currentRadius = 0;

        this.easing = BezierEasing(.31, 2.11, .24, .99);

        this.x = x;
        this.y = y;
        this.radius = radius;
        this.speedX = speedX;
        this.speedY = speedY;
    }

    move(td) {
        if (this.animationTime < this.animationDuration) {
            this.animationTime += td;
            this.currentRadius = this.radius * this.easing(this.animationTime / this.animationDuration);
        }
        else {
            this.currentRadius = this.radius;
        }

        this.x += td * this.speedX;
        this.y += td * this.speedY;
    }
}

class NodeBackground extends React.Component {
    constructor(props) {
        super(props)
        this.canvasRef = React.createRef();
        this.updateTimer = null;
    }

    start() {
        // size dependent
        // const largestSide = Math.max(this.props.width, this.props.height);
        // const smallestSide = Math.min(this.props.width, this.props.height);

        const area = (this.props.width * this.props.height) / (1200 * 800); // 1000 is the calibrated settins

        // settings
        this.fps = 60;
        this.nodeCount = 40 * area;

        this.maxLineDistance = 200;
        this.outsidePadding = 10;
        this.nodeMinRadius = 6;
        this.nodeMaxRadius = 12;
        this.nodeMinSpeed = 10;
        this.nodeMaxSpeed = 25;

        // initilization 
        this.lastUpdate = Date.now();
        this.active = true;

        // generate nodes
        this.nodes = [];
        for (let i = 0; i < this.nodeCount; i++) {
            this.addRandomNode(
                random(0, this.props.width),
                random(0, this.props.height)
            );
        }
    }

    update() {
        // get delta time
        const now = Date.now();
        const dt = (now - this.lastUpdate) / 1000;
        this.lastUpdate = now;

        if (this.active) {
            // clearing canvas
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            // moving nodes
            this.nodes.forEach(node => node.move(dt));

            // checking if nodes are ouside canvas
            this.relocateNodes();

            // drawing nodes
            for (let i = 0; i < this.nodes.length; i++) {
                const node1 = this.nodes[i];
                for (let j = i + 1; j < this.nodes.length; j++) {
                    const node2 = this.nodes[j];


                    // no overlap
                    const distance = this.getDistance(node1.x, node1.y, node2.x, node2.y);
                    if (distance < this.maxLineDistance) {
                        const alpha = (this.maxLineDistance - distance) / this.maxLineDistance;

                        this.drawLine(
                            node1.x, node1.y,
                            node2.x, node2.y,
                            alpha
                        )
                    }

                    [
                        [this.props.width + this.outsidePadding * 2, 0, 0, 0],
                        [0, this.props.height + this.outsidePadding * 2, 0, 0],
                        [0, 0, this.props.width + this.outsidePadding * 2, 0],
                        [0, 0, 0, this.props.height + this.outsidePadding * 2]
                    ].forEach(constilation => {
                        const distance = this.getDistance(
                            node1.x + constilation[0],
                            node1.y + constilation[1],
                            node2.x + constilation[2],
                            node2.y + constilation[3]
                        );

                        if (distance < this.maxLineDistance) {
                            const alpha = (this.maxLineDistance - distance) / this.maxLineDistance;

                            this.drawLine(
                                node1.x + constilation[0],
                                node1.y + constilation[1],
                                node2.x + constilation[2],
                                node2.y + constilation[3],
                                alpha
                            )

                            this.drawLine(
                                node1.x - constilation[2],
                                node1.y - constilation[3],
                                node2.x - constilation[0],
                                node2.y - constilation[1],
                                alpha
                            )
                        }
                    });
                }
            }

            // drawing nodes
            this.nodes.forEach(node => this.drawCircle(node.x, node.y, node.currentRadius));
        }

        setTimeout(() => {
            window.requestAnimationFrame(() => this.update());
        }, 1000 / this.fps);

    }

    componentDidMount() {
        this.canvas = this.canvasRef.current;
        this.ctx = this.canvas.getContext("2d");
        this.updateCanvas();
        this.start();
        this.canvas.addEventListener("mousedown", function (e) {
            let rect = this.canvas.getBoundingClientRect();
            let x = e.clientX - rect.left;
            let y = e.clientY - rect.top;
            this.addRandomNode(x, y);
        }.bind(this));

        window.requestAnimationFrame(() => this.update());
    }

    componentDidUpdate() {
        if (this.ctx.canvas.width !== this.props.width ||
            this.ctx.canvas.height !== this.props.height) {
            clearTimeout(this.updateTimer);
            this.updateTimer = setTimeout(() => {
                this.updateCanvas();
                this.start();
            }, 50)
        }

    }

    getDistance(x1, y1, x2, y2) {
        const x = Math.pow((x2 - x1), 2);
        const y = Math.pow((y2 - y1), 2);
        return Math.sqrt(x + y);
    }

    updateCanvas() {
        this.ctx.canvas.width = this.props.width;
        this.ctx.canvas.height = this.props.height;
        this.ctx.lineWidth = 4;
    }

    relocateNodes() {
        for (let i = 0; i < this.nodes.length; i++) {
            const node = this.nodes[i];



            if (node.x < (0 - this.outsidePadding) || node.x > (this.props.width + this.outsidePadding)) {
                node.x =
                    - this.outsidePadding
                    + mod(
                        node.x + this.outsidePadding,
                        2 * this.outsidePadding + this.props.width
                    );
            }


            if (node.y < (0 - this.outsidePadding) || node.y > (this.props.height + this.outsidePadding)) {
                node.y =
                    - this.outsidePadding
                    + mod(
                        node.y + this.outsidePadding,
                        2 * this.outsidePadding + this.props.height
                    );
            }

        }
    }

    addRandomNode(x, y) {
        const node = new Node(
            x,
            y,
            random(this.nodeMinRadius, this.nodeMaxRadius),
            randomSigned(this.nodeMinSpeed, this.nodeMaxSpeed),
            randomSigned(this.nodeMinSpeed, this.nodeMaxSpeed)
        );

        this.nodes.push(node);
    }

    drawLine(startX, startY, endX, endY, alpha) {
        this.ctx.beginPath();
        this.ctx.moveTo(startX, startY);
        this.ctx.lineTo(endX, endY);
        this.ctx.strokeStyle = this.props.color;
        this.ctx.globalAlpha = alpha;
        this.ctx.stroke();
        this.ctx.globalAlpha = 1;
    }

    drawCircle(x, y, radius) {
        this.ctx.beginPath();
        this.ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
        this.ctx.fillStyle = this.props.color;
        this.ctx.fill();
    }

    render() {
        return (
            <Fragment>
                <canvas
                    ref={this.canvasRef}
                    className="node-background">
                </canvas>
            </Fragment>
        )
    }
}


export default NodeBackground;