import React from "react";
import NodeBackground from "../Components/NodeBackground";
import LargeLink from "../Components/LargeLink";
import Spacer from "../Components/Spacer";
import { BsChevronDoubleDown } from "react-icons/bs"

export default function TopPage(props) {
    return (
        <div
            id="top"
            style={{
                width: props.width,
                height: props.height
            }}
            className="top-page">
            <div className="background">
                <NodeBackground
                    color={"#dddddd"}
                    width={props.width}
                    height={props.height}
                />
            </div>
            <div className="foreground">
                <div className="foreground-parent">
                    <h1>Isak Reinholdsson.</h1>
                    <h2>I build things with computers.</h2>
                    <Spacer height="large" />
                    <div className="container">
                        <LargeLink link="about" text="About" />
                        <LargeLink link="projects" text="Projects" />
                        <LargeLink link="cv" text="CV" />
                        <LargeLink link="mailto:isakre@kth.se" text="Contact" />
                    </div>
                </div>
            </div>

            <div style={{
                display:
                    (props.height < props.width && props.height < 320) ||
                        (props.height >= props.width && props.height < 460)
                        ? "none" : ""
            }} className="arrow-down">
                <a href="about">
                    <BsChevronDoubleDown />
                </a>
            </div>
        </div>
    )
}

