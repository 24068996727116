import React from "react";

export default function TopPage(props) {
    return (
        <div
            id="bottom"
            className="bottom-page" style={{
                width: props.width,
            }}>
            <div className="container">
                <h3>Want to know more?</h3>
                <div className="spacer"></div>
                <h4>Don't hesitate to get in touch: <a href="mailto:isakre@kth.se">isakre@kth.se</a>
                </h4>
            </div>
        </div>
    )
}

