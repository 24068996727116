import React, { createRef } from "react";

import TopPage from "../Components/TopPage"
import AboutPage from "../Components/AboutPage"
import Projects from "../Components/Projects"
import BottomPage from "../Components/BottomPage"



class Main extends React.Component {
	constructor(props) {
		super(props);

		this.appRef = createRef();
		this.scrollTimer = null;
		this.handleScroll = this.handleScroll.bind(this);
		this.currentTitle = "";
	}

	componentDidMount() {
		this.setState(
			{
				elements: this.getElementSizes()
			}
		);

		setTimeout(() => {
			
			this.setState(
				{
					elements: this.getElementSizes()
				},
				() => {
					this.scrollTo(this.props.location.pathname.substring(1))
					setInterval(
						() => window.addEventListener('scroll', this.handleScroll)
						, 1000
					);
				});
		}, 250)
	}

	componentDidUpdate(prevProps) {
		if ((prevProps.width !== this.props.width) || (prevProps.height !== this.props.height)) {
			this.setState({ elements: this.getElementSizes() });
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	getElementSizes() {
		const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
		const elements = this.appRef.current.children;
		let newElements = [];
		for (var i = 0; i < elements.length; i++) {
			const rect = elements[i].getBoundingClientRect();
			newElements.push([elements[i].id, rect.y + rect.height + currentScroll, rect.y + currentScroll]);
		}
		return newElements;
	}

	scrollTo(objName) {
		for (let i = 0; i < this.state.elements.length; i++) {
			if (this.state.elements[i][0] === objName) {
				window.scrollTo({ top: this.state.elements[i][2], behavior: 'smooth' })
				return;
			}
		}
		window.history.replaceState(null, "/", "/");
	}

	handleScroll() {
		// const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
		// var i = 0;
		// while (this.state.elements[i][1] <= currentScroll) {
		//     i++;
		// }
		// const currentTitle = this.state.elements[i][0] === "top" ? "/" : this.state.elements[i][0];

		// if (this.currentTitle !== currentTitle) {
		//     window.history.replaceState(null, currentTitle, currentTitle);
		//     this.currentTitle = currentTitle;
		// }


		// if (this.scrollTimer !== null) {
		//     clearTimeout(this.scrollTimer);
		// }
		// this.scrollTimer = setTimeout(() => {
		//     this.handleScroll();
		// }, 250);
	}

	render() {
		return (
			<div ref={this.appRef} className="app" >
				<TopPage
					width={this.props.width}
					height={this.props.height}
				/>
				<AboutPage
					width={this.props.width}
					height={this.props.height}
				/>
				<Projects
					width={this.props.width}
					height={this.props.height}
				/>
				<BottomPage
					width={this.props.width}
					height={this.props.height}
				/>
			</div>
		)
	}
}

export default Main;
