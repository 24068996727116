import React from 'react';
import ReactPlayer from 'react-player';
import TechnologyPanel from './TechnologyPanel';

export default function Project(props) {
	const attributes = props.attributes;
	return (
		<div className="project">
			<div className="media-container">
				<a href={attributes.src} className="background content_box">
					{attributes.content.type === "image" &&
						<img
							src={attributes.content.src}
							alt={attributes.content.src}
						/>}

					{attributes.content.type === "video" &&
						<ReactPlayer
							url={attributes.content.src}
							playing={true}
							width="auto"
							height="auto"
							light={false}
							muted={true}
							controls={false}
							loop={true}
							pip={false}
							playsinline={true}
							style={{
								display: "flex",
								maxWidth: (props.width >= 800) ? '400px' : props.width >= 400 ? '320px' : props.width*0.8 + "px",
								maxHeight: (props.width >= 800) ? '450px' : props.width >= 400 ? '320px' : props.width*0.8 + "px",
							}}
						/>
					}
				</a>
			</div>
			<div className="spacer"></div>
			<div className="content"
				style={
					attributes.width >= 800 ?
						{
							margin: attributes.position === "right" ? "100 0 0 0" : "0 0 100 0"
						}
						:
						{

						}
				}
			>
				<div className="inner">

					<h4>{attributes.title}</h4>
					<h5>{attributes.start} - {attributes.stop}</h5>
					
					<div className="spacer"></div>
					
					<p>
						{attributes.description}
					</p>
					
					<div className="spacer"></div>

					<p className="small_title">
						Technologies used:
					</p>
					<TechnologyPanel technologies={attributes.technologies} />
					
					<div className="spacer"></div>
					
					<div className="button-container">
						<a href={attributes.src} className="info">Read more</a>
					</div>	
				</div>
			</div>
		</div>
	)
}