import React from 'react'
// import { SiUnity, SiNodedotjs, SiPython, SiCsharp, SiPostgresql, SiFirebase, SiAdobephotoshop, CgMathPercent } from "react-icons/si"
import {GoChevronRight} from "react-icons/go"

// const icons = {
//     "Unity": <SiUnity />,
//     "Node.js": <SiNodedotjs />,
//     "Python": <SiPython />,
//     "C#": <SiCsharp />,
//     "PostgreSQL": <SiPostgresql />,
//     "Firebase": <SiFirebase />,
//     "Photoshop": <SiAdobephotoshop />,
// }

export default function TechnologyPanel(props) {
    const list = props.technologies.map((element, index) => <li key={index}><span><GoChevronRight/></span>{element}</li>);
    let list1 = [];
    let list2 = [];

    if (list.length < 4) {
        list1 = list;
    }
    else {
        const index = Math.ceil(list.length / 2);
        list1 = list.slice(0, index);
        list2 = list.slice(index, list.length);
    }

    return (
        <div className="technology-panel">
            <ul>
                {list1} 
                {/* <span className="icon">{icons[element]}</span> */}
            </ul>
            <ul>
                {list2}
            </ul>
        </div>
    )
}