import React from "react";
import { HiMail } from "react-icons/hi";
import { IoDocumentText } from "react-icons/io5";
import { BsGithub, BsLinkedin } from "react-icons/bs";
import profilePic from "../Assets/IsakReinholdsson.jpg";
import InfoBox from "./InfoBox";

const bday = new Date("2000-02-17");
const programming_bday = new Date("2011-02-17");

const diff_in_years = (date) => {
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - date;

  // Convert milliseconds to years
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Taking into account leap years
  const yearsSinceDate = timeDifference / millisecondsInYear;

  // Round the result to the nearest whole number
  const roundedYears = Math.floor(yearsSinceDate);
  return roundedYears;
};

export default function TopPage(props) {
  return (
    <div
      style={{
        width: props.width,
      }}
      className="about"
      id="about"
    >
      <div className="container top">
        <div className="column left">
          <h3>
            <span className="colored">About me.</span>
          </h3>
          <img src={profilePic} alt="Isak Reinholdsson" />
          <div className="links">
            <a href="mailto:isakre@kth.se" className="link">
              <div className="icon">
                <HiMail />
              </div>
              <div className="text">isakre@kth.se</div>
            </a>
            <a href="/CV" className="link">
              <div className="icon">
                <IoDocumentText />
              </div>
              <div className="text">CV/Resumé</div>
            </a>
            <a href="https://github.com/IsakRein" className="link">
              <div className="icon">
                <BsGithub />
              </div>
              <div className="text">IsakRein</div>
            </a>
            <a href="https://linkedin.com/in/IsakRein" className="link">
              <div className="icon">
                <BsLinkedin />
              </div>
              <div className="text">IsakRein</div>
            </a>
          </div>
        </div>
        <div className="column right">
          <h3>
            <span className="colored">About me.</span>
          </h3>
          <p>
            I am a {diff_in_years(bday)}-year-old software engineer from Sweden,
            currently pursuing an MSc in Computer Science at KTH Royal Institute
            of Technology, with an expected graduation in 2025.
            <br />
            <br />
            With {diff_in_years(programming_bday)}+ years of programming
            experience, I have built up a big portfolio with projects ranging
            from genetic algorithms to mobile games.
            <br />
            <br />
            Do you want help building your next great project? Contact me and
            see if we can work something out!
          </p>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="container bottom">
        <div className="info-box-container">
          <InfoBox
            width={props.width}
            title={"I'm looking for:"}
            content={
              <div>
                Since I am currently studying at KTH, I am not able to work full
                time. I am however interested in:
                <br />
                <br />
                <ul>
                  <li>Job/internship for summer 2024</li>
                  <br />
                  <li>Part time work during the semesters</li>
                  <br />
                  <li>Temporary consulting work</li>
                </ul>
                <br />
                If you want to know more, send me an email:{" "}
                <a href="mailto:isakre@kth.se">isakre@kth.se</a>
              </div>
            }
          />
          <InfoBox
            width={props.width}
            title={"I'm good at:"}
            content={
              <div>
                <b>Programming languages</b>

                <ul>
                  <li>Python</li>
                  <li>C/C++</li>
                  <li>HTML/CSS</li>
                  <li>JavaScript</li>
                  <li>Java</li>
                </ul>
                <br />

                <b>Technologies</b>
                <ul>
                  <li>React</li>
                  <li>Node.js</li>
                  <li>PostgreSQL</li>
                  <li>LaTeX</li>
                </ul>
                <br />

                <b>Other</b>
                <ul>
                  <li>Data structures and algorithms</li>
                  <li>Database design</li>
                  <li>Graphic design</li>
                  <li>3d modelling</li>
                  <li>Game design</li>
                </ul>
              </div>
            }
          />
          <InfoBox
            width={props.width}
            title={"Experience:"}
            content={
              <div>
                Check out <a href="cv">my resume</a>.
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
