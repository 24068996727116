export function random(min, max) {
    return ((Math.random() * (max - min)) + min);
}

export function randomSigned(min, max) {
    const multiplier = Math.random() >= 0.5 ? 1 : -1;
    return random(min, max) * multiplier;
}

export function mod(n, m) {
    return ((n % m) + m) % m;
}