import React, { useState, useEffect } from "react";

import './App.scss';

import Main from "./Pages/Main"
import CV from "./Pages/CV"

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";


function App() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

    useEffect(
        () => {
            function handleResize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        },
        []
    )

    // const location = useLocation();

    return (
        <Router>
            <Switch>
                <Route path="/cv">
                    <CV />
                </Route>
                <Route
                    path="/"
                    render={
                        (props) =>
                            <Main
                                width={size[0]}
                                height={size[1]}
                                {...props}
                            />
                    }
                >
                </Route>
            </Switch>
        </Router>
    )
}

export default App;
